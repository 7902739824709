
import { defineComponent } from 'vue';

import DashboardLayout from '@/layouts/DashboardLayout.vue';

export default defineComponent({
  name: 'ChangeLog',

  components: {
    DashboardLayout,
  },
});
