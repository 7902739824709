
import { defineComponent } from 'vue';

import DashboardLayout from '@/layouts/DashboardLayout.vue';

export default defineComponent({
  name: 'Dashboards',

  components: {
    DashboardLayout,
  },

  data() {
    return {
      transactionData: [{
        name: 'Status',
        options: 'Exception',
        explanation: 'There is a clear difference between the information in the Dealer’s Hedberg System and SAP.',
      },
      {
        name: '',
        options: 'Warning',
        explanation: 'There is an issue between the information in the Dealer’s Hedberg System and SAP, but it may not be a problem.',
      },
      {
        name: '',
        options: 'OK',
        explanation: 'No issue',
      },
      {
        name: '',
        options: 'Quote',
        explanation: 'This is a quote, not an order. Quotes will never have SAP data.',
      },
      {
        name: 'Alert Count',
        options: '',
        explanation: 'Number of lines with exceptions and/or warnings. If a line is cancelled by the dealer or Steelcase (not both) that line will show as part of the Alert Count. Quotes will never have Alerts.',
      },
      {
        name: 'DLR Cust Name',
        options: '',
        explanation: 'The customer’s name from the Dealer’s Hedberg System.',
      },
      {
        name: 'Cust PO #',
        options: '',
        explanation: 'The Customer’s Purchase Order number (CPO #). If the Dealer’s Hedberg System or SAP do not have a CPO #, you may see two entries for the same order.',
      },
      {
        name: 'Notes',
        options: '',
        explanation: 'Text entries by users. Notes can be used to: <ul><li>Share information about the order with other users.</li><li>Mark the order as “reviewed” to indicate that they have already reviewed the issues. </li></ul>',
      },
      {
        name: 'DLR Qte/Ord Title',
        options: '',
        explanation: 'The Quote or Order title from the dealer’s Hedberg System.',
      },
      {
        name: 'DLR Qte/Ord #',
        options: '',
        explanation: 'The Quote or Order Number from the dealer’s Hedberg System. The number will begin with the Company Code. The field will be blank if the SAP order could not be connected to a Dealer order.',
      },
      {
        name: 'Qte/Ord Type',
        options: 'Q',
        explanation: 'Quote',
      },
      {
        name: '',
        options: 'O',
        explanation: 'Order',
      },
      {
        name: 'DLR Line Count',
        options: '',
        explanation: 'Number of active or suspended lines on the quote/order from the Dealer’s Hedberg System. Cancelled lines are not counted.',
      },
      {
        name: 'STC Line Count',
        options: '',
        explanation: 'Number of active lines on the order from SAP. Cancelled lines are not counted.',
      },
      {
        name: 'DLR Ext Sell $',
        options: '',
        explanation: 'The total extended sell for all lines on the quote/order from the Dealer’s Hedberg System.',
      },
      {
        name: 'STC Ext Sell $',
        options: '',
        explanation: 'The total extended sell for all lines on the order from SAP.',
      },
      {
        name: 'STC Ack Rec’d',
        options: 'Yes',
        explanation: 'All lines have been acknowledged by Steelcase according to the Dealer’s Hedberg System.  This includes Steelcase Acknowledgments for non-Steelcase product and services as well as Steelcase product.',
      },
      {
        name: '',
        options: 'No',
        explanation: 'None of the lines have been acknowledged by Steelcase according to the Dealer’s Hedberg System.',
      },
      {
        name: '',
        options: 'Some',
        explanation: 'Some of the lines have been acknowledged by Steelcase according to the Dealer’s Hedberg System.',
      },
      ],

      transactionDetailData: [{
        name: 'Status',
        options: 'Exception',
        explanation: 'There is a clear difference between the information in the Dealer’s Hedberg System and SAP.',
      },
      {
        name: '',
        options: 'Warning',
        explanation: 'There is an issue between the information in the Dealer’s Hedberg System and SAP, but it may not be a problem.',
      },
      {
        name: '',
        options: 'OK',
        explanation: 'No issue',
      },
      {
        name: '',
        options: 'Quote',
        explanation: '',
      },
      {
        name: 'Line Status Desc',
        options: '',
        explanation: 'See Rules below.',
      },
      {
        name: 'Cust PO #',
        options: '',
        explanation: 'The Customer’s Purchase Order number (CPO #). If the Dealer’s Hedberg System or SAP do not have a CPO #, you may see two entries for the same order.',
      },
      {
        name: 'DLR Qte/Ord #',
        options: '',
        explanation: 'The Quote or Order Number from the dealer’s Hedberg System.  The number will begin with the Company Code. The field will be blank if the SAP order could not be connected to a Dealer order.',
      },
      {
        name: 'DLR Line #',
        options: '',
        explanation: 'The number assigned to the quote/order line on the Dealer’s Hedberg System.',
      },
      {
        name: 'STC Line #',
        options: '',
        explanation: 'The number assigned to the order line on SAP.',
      },
      {
        name: 'DLR Vendor #',
        options: '',
        explanation: 'The vendor assigned to the quote/order line on the Dealer’s Hedberg System.',
      },
      {
        name: 'DLR Vendor Name',
        options: '',
        explanation: 'The vendor’s name from the Dealer’s Hedberg System.',
      },
      {
        name: 'DLR Qty',
        options: '',
        explanation: 'The quantity on the quote/order line from the Dealer’s Hedberg System.',
      },
      {
        name: 'STC Qty',
        options: '',
        explanation: 'The quantity on the order line from SAP.',
      },
      {
        name: 'DLR Cat #',
        options: '',
        explanation: 'The catalog number on the order line from the Dealer’s Hedberg System.',
      },
      {
        name: 'STC Cat #',
        options: '',
        explanation: 'The catalog number on the order line from SAP.',
      },
      {
        name: 'DLR Product Line',
        options: '',
        explanation: 'The product line associated with the item ordered on the line from the Dealer’s Hedberg System. If Product Line is not available, this will be blank.',
      },
      {
        name: 'DLR Line Description',
        options: '',
        explanation: 'The first line of the product description on the order line from the Dealer’s Hedberg System.',
      },
      {
        name: 'STC Line Description',
        options: '',
        explanation: 'The first line of the product description on the order line from SAP.',
      },
      {
        name: 'DLR Ext Sell $',
        options: '',
        explanation: 'The extended sell for the order line on the Dealer’s Hedberg System.',
      },
      {
        name: 'STC Ext Sell $',
        options: '',
        explanation: 'The extended sell for the order line on SAP.',
      },
      {
        name: 'Scheduled Date',
        options: '',
        explanation: 'If the dealer is using Hedberg Scheduler, this is the Scheduled Date (planned scheduled installation date) from the Dealer’s Hedberg System. This field will be blank if the dealer is not using Hedberg Scheduler, or the line has not been scheduled.',
      },
      {
        name: 'DLR Exp Receipt Date',
        options: '',
        explanation: 'The expected receipt date from the vendor’s acknowledgment for the order line on the Dealer’s Hedberg System.  This is the date the product is expected at the dealer warehouse or Customer Site.  This will be blank if there is no expected receipt date.',
      },
      {
        name: 'DLR Rec’d Qty',
        options: '',
        explanation: 'Total quantity received for the order line on the Dealer’s Hedberg System. The field will be blank if nothing has been received yet. If the processing code on the line does not require receiving this will be "NA".',
      },
      {
        name: 'DLR Rec’d Date',
        options: '',
        explanation: 'The latest date quantity was received for the order line on the Dealer’s Hedberg System.  The field will be blank if nothing has been received yet. If the processing code on the line does not require receiving this will be "NA".',
      },
      {
        name: 'DLR Del Qty',
        options: '',
        explanation: 'Total quantity delivered on the order line on the Dealer’s Hedberg System. The field will be blank if nothing has been delivered yet. If the processing code on the line does not require delivery this will be "NA". If the DLR Received Qty & Date are "NA" and the DLR Del Qty is blank, this line is Direct Ship to Customer Site.',
      },
      {
        name: 'DLR Del Date',
        options: '',
        explanation: 'The latest date quantity was delivered for the order line on the Dealer’s Hedberg System.  The field will be blank if nothing has been delivered yet. If the processing code on the line does not require delivery this will be "NA". If the DLR Received Qty & Date are "NA" and the DLR Del Qty is blank, this line is Direct Ship to Customer Site.',
      },
      {
        name: 'STC Ack #',
        options: '',
        explanation: 'The Acknowledgment (Sales Order) Number from SAP.',
      },
      ],

      reconciliationData: [{
        name: 'Status',
        options: 'Exception',
        explanation: 'There is a clear difference between the information in the Dealer’s Hedberg System and SAP.',
      },
      {
        name: '',
        options: 'Warning',
        explanation: 'There is an issue between the information in the Dealer’s Hedberg System and SAP, but it may not be a problem.',
      },
      {
        name: '',
        options: 'OK',
        explanation: 'No issue',
      },
      {
        name: 'Alert Count',
        options: '',
        explanation: 'Number of lines with exceptions and/or warnings. If a line is cancelled by the dealer or Steelcase (not both) that line will show as part of the Alert Count. Quotes will never have Alerts.',
      },
      {
        name: 'DLR Cust Name',
        options: '',
        explanation: 'The customer’s name from the Dealer’s Hedberg System.',
      },
      {
        name: 'Cust PO #',
        options: '',
        explanation: 'The Customer’s Purchase Order number (CPO #). If the Dealer’s Hedberg System or SAP do not have a CPO #, you may see two entries for the same order.',
      },
      {
        name: 'Notes',
        options: '',
        explanation: 'Text entries by users. Notes can be used to: <ul><li>Share information about the order with other users.</li><li>Mark the order as “reviewed” to indicate that they have already reviewed the issues. </li></ul>',
      },
      {
        name: 'DLR Order #',
        options: '',
        explanation: 'The Quote or Order Number from the dealer’s Hedberg System.  The number will begin with the Company Code. The field will be blank if the SAP order could not be connected to a Dealer order.',
      },
      {
        name: 'DLR Ord Title',
        options: '',
        explanation: 'The Quote or Order title from the dealer’s Hedberg System.',
      },

      {
        name: 'DLR Line Count',
        options: '',
        explanation: 'Number of active or suspended lines on the quote/order from the Dealer’s Hedberg System. Cancelled lines are not counted.',
      },
      {
        name: 'STC Line Count',
        options: '',
        explanation: 'Number of active lines on the order from SAP. Cancelled lines are not counted.',
      },
      {
        name: 'DLR Ext Sell $',
        options: '',
        explanation: 'The total extended sell for all lines on the order from the Dealer’s Hedberg System.',
      },
      {
        name: 'STC Ext Sell $',
        options: '',
        explanation: 'The total extended sell for all lines on the order from SAP.',
      },
      {
        name: 'STC Ack Rec’d',
        options: 'Yes',
        explanation: 'All lines have been acknowledged by Steelcase according to the Dealer’s Hedberg System.  This includes Steelcase Acknowledgments for non-Steelcase product and services as well as Steelcase product.',
      },
      {
        name: '',
        options: 'No',
        explanation: 'None of the lines have been acknowledged by Steelcase according to the Dealer’s Hedberg System.',
      },
      {
        name: '',
        options: 'Some',
        explanation: 'Some of the lines have been acknowledged by Steelcase according to the Dealer’s Hedberg System.',
      },
      {
        name: 'DLR Comm Inv',
        options: 'Yes',
        explanation: 'The Steelcase Commission Customer Invoice was created on the Dealer’s Hedberg System.',
      },
      {
        name: '',
        options: 'No',
        explanation: 'The Steelcase Commission Customer Invoice was not created on the Dealer’s Hedberg System.',
      },
      {
        name: 'STC Cust Inv’d',
        options: 'Yes',
        explanation: 'The customer has been invoiced on SAP.',
      },
      {
        name: '',
        options: 'No',
        explanation: 'The customer has not been invoiced on SAP.',
      },
      {
        name: 'STC CM',
        options: 'Yes',
        explanation: 'A Commission Credit Memo has been generated on SAP that includes this order.',
      },
      {
        name: '',
        options: 'No',
        explanation: 'A Commission Credit Memo has been generated on SAP that includes this order.',
      },
      {
        name: 'DLR Est Comm $',
        options: '',
        explanation: 'The estimated commission for this order according to the Dealer’s Hedberg System.',
      },
      {
        name: 'STC Est Comm $',
        options: '',
        explanation: 'The estimated commission for this order according to SAP.',
      },
      ],

      reconciliationDetailData: [{
        name: 'Line Status',
        options: 'Exception',
        explanation: 'There is a clear difference between the information in the Dealer’s Hedberg System and SAP.',
      },
      {
        name: '',
        options: 'Warning',
        explanation: 'There is an issue between the information in the Dealer’s Hedberg System and SAP, but it may not be a problem.',
      },
      {
        name: '',
        options: 'OK',
        explanation: 'No issue',
      },
      {
        name: 'Line Status Desc',
        options: '',
        explanation: 'See Rules below.',
      },
      {
        name: 'Cust PO #',
        options: '',
        explanation: 'The Customer’s Purchase Order number (CPO #). If the Dealer’s Hedberg System or SAP do not have a CPO #, you may see two entries for the same order.',
      },
      {
        name: 'DLR Ord #',
        options: '',
        explanation: 'The Order Number from the dealer’s Hedberg System. The number will begin with the Company Code. The field will be blank if the SAP order could not be connected to a Dealer order.',
      },
      {
        name: 'STC ACK #',
        options: '',
        explanation: 'The Acknowledgment (Sales Order) Number from SAP.',
      },
      {
        name: 'DLR Line #',
        options: '',
        explanation: 'The number assigned to the line on the Dealer’s Hedberg System.',
      },
      {
        name: 'STC Line #',
        options: '',
        explanation: 'The number assigned to the order line on SAP.',
      },
      {
        name: 'DLR Vendor #',
        options: '',
        explanation: 'The vendor assigned to the order line on the Dealer’s Hedberg System.',
      },

      {
        name: 'DLR Vendor Name',
        options: '',
        explanation: 'The vendor’s name from the Dealer’s Hedberg System.',
      },
      {
        name: 'DLR Qty',
        options: '',
        explanation: 'The quantity on the order line from the Dealer’s Hedberg System.',
      },
      {
        name: 'STC Qty',
        options: '',
        explanation: 'The quantity on the order line from SAP.',
      },
      {
        name: 'DLR Cat #',
        options: '',
        explanation: 'The catalog number on the order line from the Dealer’s Hedberg System.',
      },
      {
        name: 'STC Cat #',
        options: '',
        explanation: 'The catalog number on the order line from SAP.',
      },
      {
        name: 'DLR Line Description',
        options: '',
        explanation: 'The first line of the product description on the order line from the Dealer’s Hedberg System.',
      },
      {
        name: 'STC Line Description',
        options: '',
        explanation: 'The first line of the product description on the order line from SAP.',
      },
      {
        name: 'DLR Ext Sell $',
        options: '',
        explanation: 'The extended sell for the order line on the Dealer’s Hedberg System.',
      },
      {
        name: 'STC Ext Sell $',
        options: '',
        explanation: 'The extended sell for the order line on SAP.',
      },
      {
        name: 'DLR Est Comm $',
        options: '',
        explanation: 'The estimated commission for this order line according to the Dealer’s Hedberg System.',
      },
      {
        name: 'STC Est Comm $',
        options: '',
        explanation: 'The estimated commission for this order line according to SAP.',
      },
      ],

      commissionData: [{
        name: 'Status',
        options: 'Exception',
        explanation: 'There is a clear difference between the information in the Dealer’s Hedberg System and SAP.',
      },
      {
        name: '',
        options: 'Warning',
        explanation: 'There is an issue between the information in the Dealer’s Hedberg System and SAP, but it may not be a problem.',
      },
      {
        name: '',
        options: 'OK',
        explanation: 'No issue',
      },
      {
        name: 'Alert Count',
        options: '',
        explanation: 'Number of lines with exceptions and/or warnings. If a line is cancelled by the dealer or Steelcase (not both) that line will show as part of the Alert Count. Quotes will never have Alerts.',
      },
      {
        name: 'DLR Cust Name',
        options: '',
        explanation: 'The customer’s name from the Dealer’s Hedberg System.',
      },
      {
        name: 'Cust PO #',
        options: '',
        explanation: 'The Customer’s Purchase Order number (CPO #). If the Dealer’s Hedberg System or SAP do not have a CPO #, you may see two entries for the same order.',
      },
      {
        name: 'Notes',
        options: '',
        explanation: 'Text entries by users. Notes can be used to: <ul><li>Share information about the order with other users.</li><li>Mark the order as “reviewed” to indicate that they have already reviewed the issues. </li></ul>',
      },
      {
        name: 'DLR Ord Title',
        options: '',
        explanation: 'The Quote or Order title from the dealer’s Hedberg System.',
      },
      {
        name: 'DLR Order #',
        options: '',
        explanation: 'The Quote or Order Number from the dealer’s Hedberg System. The number will begin with the Company Code. The field will be blank if the SAP order could not be connected to a Dealer order.',
      },
      {
        name: 'DLR Line Count',
        options: '',
        explanation: 'Number of active or suspended lines on the quote/order from the Dealer’s Hedberg System. Cancelled lines are not counted.',
      },
      {
        name: 'STC Line Count',
        options: '',
        explanation: 'Number of active lines on the order from SAP. Cancelled lines are not counted.',
      },
      {
        name: 'DLR Ext Sell $',
        options: '',
        explanation: 'The total extended sell for all lines on the order from the Dealer’s Hedberg System.',
      },
      {
        name: 'STC Ext Sell $',
        options: '',
        explanation: 'The total extended sell for all lines on the order from SAP.',
      },
      {
        name: 'STC Inv Sell $',
        options: '',
        explanation: 'The total extended sell for all lines on the customer’s invoice for this order in SAP.',
      },
      {
        name: 'DLR Est Comm $',
        options: '',
        explanation: 'The estimated commission for this order according to the Dealer’s Hedberg System.',
      },
      {
        name: 'STC Est Comm $',
        options: '',
        explanation: 'The estimated commission for this order according to SAP.',
      },
      {
        name: 'DLR Comm Rec’d',
        options: 'Yes',
        explanation: 'The Dealer’s Hedberg System indicates that some or all commission has been received for this order.',
      },
      {
        name: '',
        options: 'No',
        explanation: 'The Dealer’s Hedberg System indicates that no commission has been received for this order.',
      },
      {
        name: 'DLR Comm Inv #',
        options: '',
        explanation: 'The last invoice number for this order for the Steelcase Commission Customer Invoice from the Dealer’s Hedberg System.',
      },
      {
        name: 'DLR Last Inv Date',
        options: '',
        explanation: 'The latest invoice date for this order for the Steelcase Commission Customer Invoice from the Dealer’s Hedberg System.',
      },
      {
        name: 'DLR Comm Inv $',
        options: '',
        explanation: 'The invoice total for all invoices for the Steelcase Commission Customer associated with the order from the Dealer’s Hedberg System.',
      },
      {
        name: 'STC Cust Inv Last Date',
        options: '',
        explanation: 'The latest invoice date for the customer invoice for this order from SAP.',
      },
      {
        name: 'STC CM $',
        options: '',
        explanation: 'The total for all Commission Credit Memos associated with this order from SAP. ',
      },
      {
        name: 'DLR CM Rec’d',
        options: '',
        explanation: 'If only one Commission Credit Memo has been received on the Dealer’s Hedberg System, the Credit Memo Number will appear. If there are more than one credit memos the field will display "More Than One."',
      },
      ],

      commissionDetailData: [{
        name: 'Line Status',
        options: 'Exception',
        explanation: 'There is a clear difference between the information in the Dealer’s Hedberg System and SAP.',
      },
      {
        name: '',
        options: 'Warning',
        explanation: 'There is an issue between the information in the Dealer’s Hedberg System and SAP, but it may not be a problem.',
      },
      {
        name: '',
        options: 'OK',
        explanation: 'No issue',
      },
      {
        name: 'Line Status Desc',
        options: '',
        explanation: 'See Rules.',
      },
      {
        name: 'Cust PO #',
        options: '',
        explanation: 'The Customer’s Purchase Order number (CPO #). If the Dealer’s Hedberg System or SAP do not have a CPO #, you may see two entries for the same order.',
      },
      {
        name: 'DLR Ord #',
        options: '',
        explanation: 'The Order Number from the dealer’s Hedberg System. The number will begin with the Company Code. The field will be blank if the SAP order could not be connected to a Dealer order.',
      },
      {
        name: 'DLR Line #',
        options: '',
        explanation: 'The number assigned to the line on the Dealer’s Hedberg System.',
      },
      {
        name: 'STC Line #',
        options: '',
        explanation: 'The number assigned to the order line on SAP.',
      },
      {
        name: 'DLR Vendor #',
        options: '',
        explanation: 'The vendor assigned to the order line on the Dealer’s Hedberg System.',
      },
      {
        name: 'DLR Vendor Name',
        options: '',
        explanation: 'The vendor’s name from the Dealer’s Hedberg System.',
      },
      {
        name: 'DLR Ext Sell $',
        options: '',
        explanation: 'The extended sell for the order line on the Dealer’s Hedberg System.',
      },
      {
        name: 'STC Cust Inv Ext Sell $',
        options: '',
        explanation: 'The extended sell for the order line on SAP.',
      },
      {
        name: 'STC CM #',
        options: '',
        explanation: 'The Commission Credit Memo number that includes commission for this order line from SAP.',
      },
      {
        name: 'STC CM Date',
        options: '',
        explanation: 'The date the Commission Credit Memo that includes commission for this order line from SAP.',
      },
      {
        name: 'DLR Exp Comm $',
        options: '',
        explanation: 'The expected commission for this order line according to the Dealer’s Hedberg System. ',
      },
      {
        name: 'DLR Est Comm $',
        options: '',
        explanation: 'The estimated commission for this order line according to the Dealer’s Hedberg System.',
      },
      {
        name: 'STC Est Comm $',
        options: '',
        explanation: 'The estimated commission for this order line according to SAP.',
      },
      ],

      detailMessagesData: [{
        message: 'STC Order/Line not found',
        status: 'Exception',
        explanation: 'The line is not found in SAP. If this rule applies, other rules will be ignored.',
      },
      {
        message: '',
        status: 'Warning',
        explanation: 'The line is not found in SAP and the DLR Ext Sell is 0.00 (zero). If this rule applies, other rules will be ignored.',
      },
      {
        message: 'DLR Order/Line not found',
        status: 'Exception',
        explanation: 'The line is not found in the Dealer’s Hedberg System. If this rule applies, other rules will be ignored.',
      },
      {
        message: 'Ext. Sell not matching',
        status: 'Exception',
        explanation: 'SAP and the Dealer’s Hedberg System have a different total extended sell for the order line. ',
      },
      {
        message: 'Different Cat#',
        status: 'Exception',
        explanation: 'SAP and the Dealer’s Hedberg System have a different catalog number for the order line.',
      },
      {
        message: '',
        status: 'Warning',
        explanation: 'If the catalog number coming from SAP is "NONSCPRODUCT" regardless of the catalog number in the Dealer’s Hedberg System.',
      },
      {
        message: 'Qty not matching',
        status: 'Exception',
        explanation: 'SAP and the Dealer’s Hedberg System have a different quantity for the order line. ',
      },
      {
        message: 'Missing Ack',
        status: 'Warning',
        explanation: 'SAP and the Dealer’s Hedberg System do not have a STC Acknowledgment for the line.',
      },
      {
        message: 'Missing STC Ack in SAP',
        status: 'Exception',
        explanation: 'SAP does not have and Acknowledgment for this line.',
      },
      {
        message: 'Missing STC Ack in Hedberg',
        status: 'Exception',
        explanation: 'The Dealer’s Hedberg System does not have a STC Acknowledgement for this line.',
      },
      {
        message: 'Line Cancelled',
        status: 'OK',
        explanation: 'Line is cancelled in both the Dealer’s Hedberg System and SAP.',
      },
      {
        message: 'DLR Line Cancelled',
        status: 'Exception',
        explanation: 'The line is only cancelled in the Dealer’s Hedberg System, not SAP. If this rule applies, other rules will be ignored.',
      },
      {
        message: 'STC Line Cancelled',
        status: 'Exception',
        explanation: 'The line is only cancelled in SAP, not in Dealer’s Hedberg System. If this rule applies, other rules will be ignored.',
      },
      {
        message: 'No DLR Est Commission',
        status: 'Exception',
        explanation: 'There is no estimated commission on the Dealer’s Hedberg System for the order line.',
      },
      {
        message: 'NO STC Est Commission',
        status: '',
        explanation: 'There is not estimated commission on SAP for the order line.',
      },
      {
        message: 'Commission Owed',
        status: 'Exception',
        explanation: 'At the Header, STC Cust Inv Last Date exists, DLR Comm Inv $ is greater than 0 and STC CM $ is blank. This description will appear on all lines. Steelcase releases Commercial Direct Sell Order commissions the Monday after the customer is invoiced. Although this status description will appear, this does not apply to GSA orders as GSA order commissions are released when the Certificate of Acceptance is received by Steelcase.',
      },
      {
        message: 'Commission Discrepancy',
        status: 'Exception',
        explanation: 'At the Header, STC CM $ and DLR Comm Inv $ are different. This description will appear on all lines. If both are zero or blank, this rule will not apply.',
      },
      ],

    };
  },
});
